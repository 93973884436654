<template>
  <div
    class="wrapper create-page"
    v-if="$store.state.USER.user.termRemainingTeams !== null"
  >
    <!-- Success Message -->
    <div
      class="flex flex-col w-full h-full p-4 border-2 rounded-lg shadow-md lg:w-1/2"
      v-if="!!newTeam"
    >
      <h3 class="page-titles">
        {{ newTeam.name }} created!
      </h3>
      <div class="my-4">
        <h5>
          Press <router-link
            class="link"
            to="/overview/0"
          >
            here
          </router-link> to distribute the tests.
        </h5>
      </div>
    </div>

    <div
      v-else
    >
      <div class="mb-8">
        <h3 class="page-titles">
          Create Team
        </h3>
        <p>Specify team name, members and key information</p>
      </div>

      <div class="flex flex-row gap-10">
        <div class="w-1/2 form-control">
          <p class="mb-4">
            Team Name*
          </p>
          <input
            v-model="name"
            data-theme="mytheme"
            type="text"
            class="input mb-4"
          >
          <span
            v-if="showNameMsg"
            class="text-lg text-red-700"
          >* Please enter a name for your team</span>
          <!--  -->
          <!--  -->
        </div>
        <div class="w-1/2 form-control flex justify-center text-base">
          <p style="font-size: 1.3rem">
            *Choose a unique and as relevant team name as possible to facilitate your own work
          </p>
        </div>
      </div>

      <div class="divider h-1/2 mt-12 mb-20" />

      <div class="addMembers">
        <h5 class="font-medium mb-8">
          Add team members
        </h5>

        <p>
          Team members (
          {{
            (rowsData.length === 0 ? 0 : rowsData.length) + '/' + this.maxTeamMembers
          }}
          )
        </p>

        <div
          style="height: 70px;"
        >
          <div
            v-show="showTeamRoleInfo"
            class="info-tooltip absolute inline-block w-64 px-4 py-3 text-white bg-white text-center rounded-lg"
            style="box-shadow: rgba(0, 0, 0, 0.5) 6px 6px 10px; left: 30%; bottom: 50%; position: relative;"
          >
            <span class="inline-block text-sm leading-tight text-black">The individual’s role in the team. If same as position repat position</span><span
              class="absolute bottom-0 right-0 w-5 h-5 -mb-1 transform rotate-45 bg-white"
              style="left: 50%;"
            />
          </div>

          <div
            v-show="showPositionInfo"
            class="info-tooltip absolute inline-block w-64 px-4 py-3 text-white bg-white text-center rounded-lg"
            style="box-shadow: rgba(0, 0, 0, 0.5) 6px 6px 10px; left: 43%; position: relative;"
          >
            <span class="inline-block text-sm leading-tight text-black">Current position in the company</span><span
              class="absolute bottom-0 right-0 w-5 h-5 -mb-1 transform rotate-45 bg-white"
              style="left: 50%;"
            />
          </div>

          <div
            v-show="showBusinessInfo"
            class="info-tooltip absolute inline-block w-64 px-4 py-3 text-white bg-white text-center rounded-lg"
            style="box-shadow: rgba(0, 0, 0, 0.5) 6px 6px 10px; left: 70%; position: relative;"
          >
            <span class="inline-block text-sm leading-tight text-black">The exact name of the business area</span><span
              class="absolute bottom-0 right-0 w-5 h-5 -mb-1 transform rotate-45 bg-white"
              style="left: 50%;"
            />
          </div>

          <div
            v-show="showDepartmentInfo"
            class="info-tooltip absolute inline-block w-64 px-4 py-3 text-white bg-white text-center rounded-lg"
            style="box-shadow: rgba(0, 0, 0, 0.5) 6px 6px 10px; position: relative; left: 85%;"
          >
            <span class="inline-block text-sm leading-tight text-black">The exact name of the department</span><span
              class="absolute bottom-0 right-0 w-5 h-5 -mb-1 transform rotate-45 bg-white"
              style="left: 50%;"
            />
          </div>
        </div>

        <div class="table-wrapper">
          <table
            class="w-full border-collapse border-2 border-gray-300 border-spacing-0 overflow-hidden"
            style="border-radius: 10px;"
          >
            <thead>
              <tr>
                <th style="width: 2rem;" />
                <th>
                  <div class="headers">
                    Name
                  </div>
                </th>
                <th>
                  <div class="headers">
                    Email
                  </div>
                </th>
                <th>
                  <div class="headers flex">
                    Team Role
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="{2}"
                      stroke="currentColor"
                      className="w-2 h-2 ml-2"
                      style="height: 2rem; margin-left: .2rem; padding: 0;"
                      @mouseover="showTeamRoleInfo=true"
                      @mouseout="showTeamRoleInfo=false"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                  </div>
                </th>
                <th>
                  <div class="headers flex">
                    Position
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="{2}"
                      stroke="currentColor"
                      className="w-2 h-2 ml-2"
                      style="height: 2rem; margin-left: .2rem; padding: 0;"
                      @mouseover="showPositionInfo=true"
                      @mouseout="showPositionInfo=false"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                  </div>
                </th>
                <th>
                  <div class="headers">
                    Company
                  </div>
                </th>
                <th>
                  <div class="headers flex">
                    Business area

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="{2}"
                      stroke="currentColor"
                      className="w-2 h-2 ml-2"
                      style="height: 2rem; margin-left: .2rem; padding: 0;"
                      @mouseover="showBusinessInfo=true"
                      @mouseout="showBusinessInfo=false"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                  </div>
                </th>
                <th>
                  <div class="headers flex">
                    Department
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="{2}"
                      stroke="currentColor"
                      className="w-2 h-2 ml-2"
                      style="height: 2rem; margin-left: .2rem; padding: 0;"
                      @mouseover="showDepartmentInfo=true"
                      @mouseout="showDepartmentInfo=false"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                  </div>
                </th>
                <th /> <!-- Add button column -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td />
                <td>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.name"
                    placeholder="John Smith"
                    required
                  >
                </td>
                <td>
                  <input
                    type="email"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.email"
                    placeholder="example@email.com"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    required
                  >
                </td>
                <td>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.teamRole"
                    placeholder="Leader"
                    required
                  >
                </td>
                <td>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.position"
                    placeholder="Developer"
                    required
                  >
                </td>
                <td>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.company"
                    placeholder="Company AB"
                    required
                  >
                </td>
                <td>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.division"
                    placeholder="Technology"
                    required
                  >
                </td>
                <td>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.department"
                    placeholder="IT"
                    required
                  >
                </td>
                <td>
                  <button
                    class="addbutton"
                    @click="addMember"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
              <tr
                v-for="(rowData, index) in rowsData"
                :key="index"
              >
                <td>{{ index+1 }}</td>
                <td>{{ rowData.name }}</td>
                <td>{{ rowData.email }}</td>
                <td>{{ rowData.teamRole }}</td>
                <td>{{ rowData.position }}</td>
                <td>{{ rowData.company }}</td>
                <td>{{ rowData.division }}</td>
                <td>{{ rowData.department }}</td>
                <td>
                  <button
                    class="removebutton"
                    @click="removeMember(index)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-12 h-12"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex flex-col gap-2 m-2">
        <span
          v-if="showInputMsg"
          class="text-lg text-red-700"
        >* Please fill all input fields</span>
        <span
          v-if="showMemberMsg"
          class="text-lg text-red-700"
        >* Please add members to your team</span>
        <span
          v-if="showLimitMsg"
          class="text-lg text-red-700"
        >* Maximum number of team members reached.</span>
        <span
          v-if="formData.email && !isValidEmail(formData.email)"
          class="text-lg text-red-700"
        >* Please enter a valid email address</span>
      </div>

      <div class="divider h-1/2 my-32" />

      <div class="ability-tests">
        <h3>
          Select Test and Questionnaire
        </h3>
        <p>Decide if you want only your team(s) Cognitive Profile, a complementary self-assessment questionnaire, and a 360 estimation of cognitive abilities</p>

        <div
          class="option-box"
          v-if="testConfigurations.length > 0"
        >
          <div class="grid gap-12">
            <div class="...">
              <test-button
                :disabled="true"
                :config="{ ...this.testConfigurations.find(cfg => cfg.name === 'Full Cognitive Profile'), description: '' }"
                v-model="fullCog"
              />
              <test-button
                :config="personalityConfig"
                v-model="personality"
              />
              <test-button
                :config="estimationConfig"
                v-model="estimation"
              />
            </div>
          </div>

          <div class="bottom-arrow">
            <svg
              width="63"
              height="36"
              viewBox="0 0 63 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M57.5614 9.57702L31.5 35.2975L5.43864 9.57702L57.5614 9.57702Z"
                stroke="#C8C8C8"
              />
              <line
                x1="7"
                y1="9.47302"
                x2="56"
                y2="9.47302"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div
      class="questionaire"
      v-if="testConfigurations.length > 0 "
    >
      <div class="grid justify-items-center btn-pane">
        <button
          class="btn-primary"
          :class="{'loading': loading}"
          @click="create"
        >
          Create team
        </button>
      </div>
    </div>
    <div>
      <input
        type="checkbox"
        id="out-of-campaigns-modal"
        class="hidden modal-toggle"
        :checked="showOutOfTeamsModal"
      >
      <div

        class="modal"
      >
        <div
          class="modal-box"
          style="min-width: 620px;"
        >
          <h3 class="text-base font-semibold mb-4">
            Upgrade your subscription to get access to more campaigns per month.
          </h3>
          <p class="py-4">
            You can upgrade your subscription <router-link
              :to="{name: 'Checkout'}"
              class="link"
            >
              here.
            </router-link>
          </p>
          <div class="modal-action">
            <div class="modal-action">
              <label
                for="out-of-campaigns-modal"
                class="btn-secondary"
              >Close</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="fixed inset-0 z-10 overflow-y-auto"
    v-else
  >
    <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
      <div class="mt-2">
        <p class="text-md text-gray-500">
          You do not currently have an active Team Brain subscription. To gain access to this service, change your subscription plan to a Team Brain plan under My profile or by clicking
          <a
            :href="notSubscribedLink + '/checkout/?fromTrial=false'"
            class="text-blue-600 dark:text-blue-500 hover:underline"
          >
            here
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import TestButton from '../components/TestButton.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    TestButton
  },
  data () {
    return {
      fullCog: true,
      personality: false,
      estimation: false,
      name: null,
      selectedConfigs: [],
      newTeam: null,
      loading: false,
      showNameMsg: false,
      showInputMsg: false,
      showMemberMsg: false,
      showLimitMsg: false,
      showOutOfTeamsModal: false,
      showBusinessInfo: false,
      showPositionInfo: false,
      showTeamRoleInfo: false,
      showDepartmentInfo: false,
      personalityConfig: {
        id: 'PERSONALITY',
        name: 'Personality++',
        description: 'A highly validated questionnaire. The outcome is a trait/characteristics profile including: Big five personality traits, focus on details, impulsivity, empathy, social style and emotional regulation.',
        durationMinutes: 10
      },
      estimationConfig: {
        id: 'ESTIMATION',
        name: '360 Estimation',
        description: 'Each team member estimates own and team members abitities tested in the cognitive test.',
        durationMinutes: 10
      },
      selectedTestInd: 'Attention control',
      formData: {
        name: '',
        email: '',
        teamRole: '',
        division: '',
        department: '',
        company: '',
        position: ''
      },
      rowsData: [],
      maxTeamMembers: 10,
      notSubscribedLink: ''
    }
  },
  computed: {
    ...mapGetters({ testConfigurations: 'CONFIGS/getTestConfigurations', subscription: 'USER/getSubscription' })
  },
  async mounted () {
    await this.$store.dispatch('CONFIGS/getTestConfigurations')
    this.notSubscribedLink = window.location.origin
  },
  methods: {
    isValidEmail (email) {
      // Regular expression to validate email format
      const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i
      return emailPattern.test(email)
    },
    addMember () {
      // Check if all input fields are filled
      if (Object.values(this.formData).every(value => value.trim() !== '')) {
        this.showInputMsg = false
        // Check if the maximum number of team members has been reached
        if (this.rowsData.length < this.maxTeamMembers) {
          // Add the current form data to the rowsData array
          this.rowsData.push({ ...this.formData })
        } else {
          this.showLimitMsg = true
        }
        this.showMemberMsg = false
        // Reset the form data after adding a row
        this.formData = {
          name: '',
          email: '',
          teamRole: '',
          division: '',
          department: '',
          company: ''
        }
      } else {
        this.showInputMsg = true
      }
    },
    removeMember (index) {
      this.rowsData.splice(index, 1)
    },
    async create () {
      this.showNameMsg = false
      this.showInputMsg = false
      this.showMemberMsg = false
      this.showOutOfTeamsModal = false

      // form check
      if (this.name == null) {
        this.showNameMsg = true
        return
      }

      // member check
      if (this.rowsData.length === 0) {
        this.showMemberMsg = true
        return
      }

      const selectedConfigs = []

      if (this.fullCog) {
        selectedConfigs.push(this.testConfigurations.find(cfg => cfg.name === 'Full Cognitive Profile').id)
      }
      if (this.personality) {
        selectedConfigs.push(this.personalityConfig.id)
      }

      // Modify the value of this.estimation
      const estimationValue = this.estimation ? 'cognitive' : null

      // create body for API
      const body = {
        name: this.name,
        testConfigurations: selectedConfigs,
        include360Estimation: estimationValue,
        teamMembers: this.rowsData
      }

      this.loading = true
      const res = await this.$api.createTeam(body)

      if (res.error) {
        if (res.error === 'out_of_teams') {
          this.showOutOfTeamsModal = true
          return
        }
      }

      await this.$store.dispatch('USER/updateSubscriptionStatus')

      this.newTeam = res
      this.loading = false
      this.$router.push('/teams/0')
      console.log('team created!')
    }
  }
}
</script>

<style lang="scss" scoped>@import '../create.scss';</style>
